<template>
    <SDPWrapper extrathin>
        <div class="disclaimer pb-4">
            <p>Nasdaq® is a registered trademark of Nasdaq, Inc. The information contained above is provided for
                informational and educational purposes only, and nothing contained herein should be construed as
                investment advice, either on behalf of a particular security or an overall investment strategy.
            </p>
            <p>The Product(s) is not sponsored, endorsed, sold or promoted by Nasdaq, Inc. or its affiliates(Nasdaq,
                with its affiliates, are referred to as the “Corporations”). The Corporations have not passed on the
                legality or suitability of, or the accuracy or adequacy of descriptions and disclosures relating to, the
                Product(s). The Corporations make no representation or warranty, express or implied to the owners of the
                Product(s) or any member of the public regarding the advisability of investing in securities generally
                or in the Product(s) particularly, or the ability of the Nasdaq-100 Volatility Control 12%TM Index (the
                “Index”) to track general stock market performance. The Corporations' only relationship to Midland
                National® Life Insurance Company (“Licensee”) is in the licensing of the Index and certain trade names
                of the Corporations and the use of the Index which is determined, composed and calculated by Nasdaq
                without regard to Licensee or the Product(s). Nasdaq has no obligation to take the needs of the Licensee
                or the owners of the Product(s) into consideration in determining, composing or calculating the Index.
                The Corporations are not responsible for and have not participated in the determination of the timing
                of, prices at, or quantities of the Product(s) to be issued or in the determination or calculation of
                the equation by which the Product(s) is to be converted into cash. The Corporations have no liability in
                connection with the administration, marketing or trading of the Product(s).
            </p>
            <p>The corporations do not guarantee the accuracy and/or uninterrupted calculation of the index or any data
                included therein. The corporations make no warranty, express or implied, as to results to be obtained by
                licensee, owners of the product(s), or any other person or entity from the use of the index or any data
                included therein. The corporations make no express or implied warranties, and expressly disclaim all
                warranties of merchantability or fitness for a particular purpose or use with respect to the index or
                any data included therein. Without limiting any of the foregoing, in no event shall the corporations
                have any liability for any lost profits or special, incidental, punitive, indirect, or consequential
                damages, even if notified of the possibility of such damages.
            </p>
            <p>Ages shown are suggestions of fit for the specific strategy and may or may not correspond to the minimum
                and maximum ages of the underlying policies. All policies are subject to review and may not be available
                in all states.
            </p>
            <p>Fixed-index annuities are not direct investments in their underlying market index. Fixed-index annuities
                are not guaranteed to grow in value. Strategies not containing a fixed fund have no guaranteed interest
                rate. Fixed-indexed annuities cannot lose their principal value, but are not guaranteed to never lose
                value. All rates of return shown for illustrative purposes only and are based on historical data.
                Displayed rates are not intended to predict future performance or act as a guarantee.
            </p>
            <p>Not financial or tax advice. Carefully consider your investment goals and needs for liquidity.</p>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import SDPWrapper from './SDPWrapper.vue';
</script>

<style>
.disclaimer {
    font-size: 9px;
    line-height: 1.15em;
    color: #666;
}

.disclaimer p {
    margin-top: 1em;
}
</style>