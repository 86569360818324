<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-50" @close="$emit('close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    :class="[blurBg && 'backdrop-blur-sm']" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0"
                    :class="[centerOnMobile ? 'items-center' : 'items-end']">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-8">
                            <div>
                                <div class="">
                                    <DialogTitle as="h3" class="mt-3 text-2xl font-bold font-serif text-gray-900">
                                        {{ title }}</DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">{{ body }}</p>
                                        <slot name="default"></slot>
                                    </div>
                                </div>
                            </div>
                            <slot name="body"></slot>
                            <div class="mt-5 flex gap-3">
                                <SecondaryButton class="w-full" @click="$emit('click:button1')" v-if="button1"
                                    :loading="button1Loading" :disabled="button1Disabled">{{
                                        button1
                                    }}
                                </SecondaryButton>
                                <PrimaryButton :loading="button2Loading" class="w-full" @click="$emit('click:button2')"
                                    v-if="button2" :disabled="button2Disabled">{{
                                        button2
                                    }}
                                </PrimaryButton>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PrimaryButton, SecondaryButton } from '@reviseannuity/randolph-ui';

const {
    open,
    title,
    body,
    button1,
    button2,
    button1Disabled = false,
    button2Disabled = false,
    button1Loading = false,
    button2Loading = false,
    blurBg = false,
    centerOnMobile = true,
} = defineProps<{
    open: boolean
    title?: string
    body?: string
    button1?: string
    button2?: string
    button1Disabled?: boolean
    button2Disabled?: boolean
    button1Loading?: boolean
    button2Loading?: boolean
    blurBg?: boolean
    centerOnMobile?: boolean
}>()

defineEmits(['click:button1', 'click:button2', 'close'])
</script>