<template>
    <div class="text-xs font-semibold text-white bg-primary-600 flex items-center gap-1 whitespace-nowrap"
        :class="[pill ? 'px-2.5 py-0.5 rounded-full' : 'px-2.5 py-1.5 rounded-bl']">
        <HandThumbUpIcon class="w-3 inline-block" />
        Best
        Fit
    </div>
</template>

<script setup lang="ts">
import { HandThumbUpIcon } from '@heroicons/vue/20/solid';

const { pill = false } = defineProps<{
    pill?: boolean
}>()
</script>