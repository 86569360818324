<template>
    <div class="mb-3">
        <div class="font-bold ">Example allocation</div>
        <p class="text-gray-600 text-xs">Your annuity could be credited based on this blend of indices.</p>
    </div>
    <div class="h-6 w-full relative bg-gray-100 flex mb-4 rounded-full z-[0]">
        <div v-for="index, i in indices"
            :style="{ width: (index.percentage * 100) + '%', backgroundColor: index.color }" class="h-full group"
            :class="{ 'rounded-l-full': i === 0, 'rounded-r-full': i === indices.length - 1 }">
            <div
                class="absolute z-0 top-[120%] group-hover:top-[105%] opacity-0 group-hover:opacity-100 transition-all">
                <div class="text-[0.7rem] leading-0 text-center px-1.5 py-0.5 bg-black text-white rounded">
                    {{ index.index }}: <strong>{{ index.percentage * 100 }}%</strong></div>
            </div>
        </div>
    </div>
    <table class="text-left text-xs w-full">
        <tr v-for="index in indices" class="border-b hover:bg-primary-300 hover:bg-opacity-20">
            <td class="whitespace-nowrap px-1 py-2">
                <div class="h-3 w-3 rounded" :style="{ backgroundColor: index.color }" />
            </td>
            <td class="whitespace-nowrap px-1 py-2">{{ index.index }}</td>
            <td class="whitespace-nowrap px-1 py-2 font-semibold">{{ index.percentage * 100 }}%</td>
        </tr>
    </table>
</template>

<script setup lang="ts">
const { indices } = defineProps<{
    indices: Array<{
        index: string
        percentage: number
        color: string
    }>
}>()
</script>