<template>
    <fieldset>
        <legend>Corporation</legend>
        <TextField label="Name" name="corporationName" />
        <TextField label="Tax ID (TIN)" name="corporationTaxId" />
        <TextField label="Address" name="corporationAddress" />
        <TextField label="City" name="corporationCity" />
        <TextField label="State" name="corporationState" />
        <TextField label="Zip" name="corporationZip" />
        <PhoneField label="Phone" name="corporationPhone" />
        <TextField label="Email" name="corporationEmail" />
        <TextField label="Date of Formation" name="corporationDateOfFormation" type="date" />
    </fieldset>
</template>

<script setup lang="ts">
import { PhoneField, TextField } from '@reviseannuity/randolph-ui';
</script>