<template>
    <SDPWrapper>
        <div class="text-center sm:px-16">
            <CheckBadgeIcon class="w-12 h-12 text-primary-600 inline-block mb-2" />
            <h2 class="text-xl sm:text-2xl font-bold font-serif mb-2">Revise Money-Back Guarantee</h2>
            <p class="text-lg">At Revise, your satisfaction is guaranteed. You can receive a full refund of your
                premium, for any reason, up to 30 days after receiving your policy.</p>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { CheckBadgeIcon } from '@heroicons/vue/20/solid';
import SDPWrapper from './SDPWrapper.vue';
</script>