<template>
    <div class="grid content-center text-center px-3 py-4 text-white rounded-md border"
        :class="[color ? 'bg-primary-600' : 'bg-white']">
        <div class="font-semibold uppercase mb-2 text-xs" :class="[color ? 'text-primary-100' : 'text-gray-800']">{{
            title
            }}
            <Tooltip :text="tooltip" v-if="tooltip" :color="color ? 'white' : '#1454f2'" />
        </div>
        <div class="text-4xl font-bold font-serif text-primary-600"
            :class="[color && 'text-white', stat < 0 && 'text-red-600']"> {{ formatter ? formatter(stat) : stat
            }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { Tooltip } from '@reviseannuity/randolph-ui'

defineProps<{
    title: string,
    stat: number,
    color?: boolean,
    tooltip?: string,
    error?: boolean,
    formatter?: (arg: number) => string
}>()
</script>