<template>
    <div class="border border-gray-300 px-4 py-4 rounded-lg w-full">
        <ul class="text-sm text-primary-950 space-y-2 ">
            <h3 class="text-primary-800 text-sm uppercase font-semibold text-center mb-4">Why choose Revise</h3>
            <li v-for="bullet in bulletPoints" class="relative pl-6 block">
                <CheckBadgeIcon class="text-primary-600 w-4 h-4 inline-block absolute top-0.5 left-0" />
                {{ bullet }}
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { CheckBadgeIcon } from '@heroicons/vue/20/solid'
import { bulletPoints } from '../../data/strategyList'
</script>