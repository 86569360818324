<template>
    <SDPWrapper id="reviews">
        <div class="sm:flex justify-between items-center mb-6 sm:mb-4">
            <h2 class="text-xl sm:text-2xl font-bold font-serif mb-2 sm:mb-0">Customer
                Reviews</h2>
            <div class="flex gap-2 items-center">
                <span class="text-gray-400 text-xs">
                    ({{ reviews?.length }} {{ reviews?.length > 1 ? 'reviews' : 'review' }})
                </span>
                <StarRating :rating="rating" />
            </div>
        </div>
        <div class="flex flex-col gap-8 sm:gap-0">
            <div v-for="review in reviews" class="sm:p-4">
                <div class="flex items-center gap-3 mb-2 sm:mb-1">
                    <div class="w-6 h-6 rounded-full bg-gray-200 text-gray-400 flex">
                        <UserIcon class="w-6 h-6 m-auto -mb-1" />
                    </div>
                    <p class="sm:text-lg font-serif font-bold text-primary-950">{{ review.reviewerName }}</p>
                </div>
                <div class="sm:flex justify-start gap-3 items-center mb-1 text-sm space-y-2 sm:space-y-0">
                    <StarRating :rating="review.rating" small />
                    <strong class="font-semibold inline-block">{{ review.title }}</strong>
                </div>
                <div class="text-xs text-gray-600">Reviewed on {{ formatDate(new Date(review.created_at)) }}<span
                        v-if="review.verified"> •
                        <strong class="text-primary-600">Verified
                            Purchase</strong></span></div>
                <p class="mt-4 text-sm">"{{ review.review }}"</p>

            </div>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { formatDate } from '@/utils';
import { UserIcon } from '@heroicons/vue/20/solid';
import { StarRating } from '@reviseannuity/randolph-ui';
import SDPWrapper from './SDPWrapper.vue';

defineProps<{
    reviews: Array<{
        reviewerName: string
        rating: number
        title: string
        review: string
        created_at: string
        verified: boolean
    }>
    rating: number
}>()
</script>