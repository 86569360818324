<template>
    <div class="border-b px-4 sm:px-8" :class="[
        thin && 'py-4 sm:py-8',
        extrathin && 'py-2 sm:py-4',
        !thin && !extrathin && ' py-8 sm:py-16',
    ]">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
const {
    thin = false,
    extrathin = false
} = defineProps<{
    thin?: boolean
    extrathin?: boolean
}>()
</script>