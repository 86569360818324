<template>
    <SDPWrapper>
        <YoutubeEmbed youtube-id="aDaYwUt4dVk" :video-cover="overviewVideoCover" />
    </SDPWrapper>
</template>

<script setup lang="ts">
import YoutubeEmbed from '@/components/YoutubeEmbed.vue';
import overviewVideoCover from '@/assets/overview-video-cover.webp'
import { SDPWrapper } from '@/components/strategyDetailsSections';
</script>