<template>
    <SDPWrapper thin>
        <div class="uppercase text-gray-500 font-semibold text-xs mb-4 text-center">Your investment is backed by
            top-rated carriers such as:
        </div>
        <div class="w-full flex flex gap-x-4 gap-y-6 sm:gap-x-8 flex-wrap justify-center items-center mb-4">
            <img :src="allianzLogo" class="max-w-20 sm:max-w-24" />
            <img :src="lincolnLogo" class="max-w-20 sm:max-w-24" />
            <img :src="midlandLogo" class="max-w-20 sm:max-w-24" />
            <img :src="nassauLogo" class="max-w-20 sm:max-w-24" />
            <img :src="nationwideLogo" class="max-w-20 sm:max-w-24" />
        </div>
        <div class="uppercase text-gray-500 font-semibold text-xs text-center">and more...
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import SDPWrapper from './SDPWrapper.vue';
import lincolnLogo from '@/assets/lincoln-financial.svg'
import nationwideLogo from '@/assets/nationwide.svg'
import allianzLogo from '@/assets/allianz.svg'
import nassauLogo from '@/assets/nassau.webp'
import midlandLogo from '@/assets/midland.png'
</script>