<template>
    <SDPWrapper>
        <SDPTitle>Compare us to similar strategies.</SDPTitle>
        <SDPDescription>It's not enough to compare isolated product features such
            as indices, crediting, and caps/floors, but to calculate and compare overall long-term performance. Our
            pre-allocated strategies are constructed with only the top-performing annuity products so you always get the
            best deal. See how our partner carriers stack up.</SDPDescription>
        <div>
            <div
                class="p-4 lg:p-8 border-2 border-primary-600 bg-white rounded-lg shadow-md relative grid lg:grid-cols-3 items-center gap-4 lg:gap-8">
                <div class="row-start-1">
                    <img :src="strategy.underlyingProduct.insurer.logo[0].url" class="w-20 lg:w-40 max-h-16 mx-auto" />
                </div>
                <div class="text-center  row-start-3 lg:row-start-1">
                    <strong class="block lg:mb-2 text-[0.6em] uppercase font-semibold text-gray-400">Underlying {{
                        strategy.indices.length
                            > 1 ? 'Indices' : 'Index' }}</strong>
                    <p class="text-center">
                        <span v-for="index of strategy.indices" class="block text-gray-900 text-xs truncate">{{
                            index.name }}</span>
                    </p>
                </div>
                <div class="text-center whitespace-nowrap row-start-2 lg:row-start-1">
                    <strong class="block lg:mb-2 text-[0.6em] uppercase font-semibold text-gray-400">10 Yr Net
                        Effective<sup>*</sup></strong>
                    <span class="block font-serif font-bold text-3xl text-primary-600">{{
                        formatPercentage(strategy.historicalRate)
                    }}<sup>%</sup></span>
                </div>
            </div>
            <div class="mx-3 rounded-b-lg overflow-hidden shadow divide-y divide-gray-200">
                <div class="px-8 py-6 bg-white grid grid-cols-2 lg:grid-cols-3 items-center gap-x-4 lg:gap-8"
                    v-for="comparison of comparisons">
                    <div class="row-start-1">
                        <img :src="comparison.insurer[0].url" class="w-20 lg:w-40 max-h-8 lg:mx-auto" />
                    </div>
                    <div class="lg:text-center lg:col-start-2">
                        <p>
                            <span v-for="index of comparison.indices" class="text-gray-900 text-xs">{{ index
                                }}</span>
                        </p>
                    </div>
                    <div
                        class="text-right lg:text-center whitespace-nowrap row-start-1 row-end-3 lg:row-end-2 lg:col-start-3">
                        <span class="block font-serif font-bold text-2xl text-gray-600">{{
                            formatPercentage(comparison.rate) }}<sup>%</sup></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="disclaimer text-center lg:mt-4">
            <p>* The displayed rates are calculated using the most recent 10 years of historical data and are shown for
                illustrative purposes only, not as a guarantee or prediction of future rates.</p>
        </div>
    </SDPWrapper>
</template>

<script setup lang="ts">
import { Strategy } from '@/types';
import { formatPercentage } from '@/utils';
import SDPDescription from './SDPDescription.vue';
import SDPTitle from './SDPTitle.vue';
import SDPWrapper from './SDPWrapper.vue'

defineProps<{
    strategy: Strategy
    comparisons: Array<{
        insurer: Array<{
            url: string
        }>
        indices: Array<string>
        rate: number
    }>
}>()
</script>